import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

function MultiSelectInput({ questionName, question, value, onChange, onBlur }) {
  const formattedOptions = Object.entries(question.options).map(([value, label]) => ({ value, label }));
  const formattedValue = value?.map(val => formattedOptions.find(option => option.value === val))?.filter(Boolean) || [];
  return (
    <Select
      className="question_block_multi_select_input"
      id={questionName}
      name={`${questionName}[]`}
      closeMenuOnSelect={false}
      defaultValue={formattedValue}
      isMulti
      options={formattedOptions}
      onChange={onChange}
      onBlur={onBlur}
      required={!question.optional}
    />
  );
}

MultiSelectInput.propTypes = {
  questionName: PropTypes.string.isRequired,
  question: PropTypes.shape({
    locked: PropTypes.bool,
    options: PropTypes.object.isRequired,
    optional: PropTypes.bool,
  }).isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

MultiSelectInput.defaultProps = {
  value: [],
};

export default MultiSelectInput;
