class CompanySettingsPage {
  static handle_switch_click(e) {
    let $switch = $(e.target)

    let setting_identifier = $switch.data('setting_identifier');
    let checked = $switch.is(':checked');
    let url = $switch.data('url');

    CompanySettingsPage.post_data(setting_identifier, checked, url)
    if ($switch[0].name == "enable_subproducer_registrationis_selected")
    {
      CompanySettingsPage.handle_agent_registration_link_visibility(checked);
    }
  }

  static handle_agent_registration_link_visibility(value) {
    if (value)
    {
      $('#copy_agent_registration_button').removeClass('hidden');
    }
    else
    {
      $('#copy_agent_registration_button').addClass('hidden');
    }
  }

  static handle_select_click(e) {
    let $select = $(e.target)

    let setting_identifier = $select.data('setting_identifier')
    let selected_value = $select.find(":selected").val();
    let url = $select.data('url')

    CompanySettingsPage.post_data(setting_identifier, selected_value, url)
  }

  static handle_text_update(e) {
    let $text = $(e.target)

    let setting_identifier = $text.data('setting_identifier');
    let value = $text.val();
    let url = $text.data('url');

    CompanySettingsPage.post_data(setting_identifier, value, url)
  }

  static handle_integer_update(e) {
    let $number = $(e.target)

    let setting_identifier = $number.data('setting_identifier');
    let value = parseInt($number.val());

    if(isNaN(value)) {
      alert('Value must be an integer')
      return
    }

    let min = $number.attr('min')
    if(min) {
      min = parseInt(min)
      if(value < min) {
        alert(`Value must be greater than or equal to ${min}`)
        return
      }
    }

    let max = $number.attr('max')
    if(max) {
      max = parseInt(max)
      if(value > max) {
        alert(`Value must be less than or equal to ${max}`)
        return
      }
    }

    let url = $number.data('url');

    CompanySettingsPage.post_data(setting_identifier, value, url)
  }

  static handle_email_update(e) {
    let $text = $(e.target)

    let setting_identifier = $text.data('setting_identifier')
    let value = $text.val();
    let url = $text.data('url');

    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    $.each(value.split(','), function(index, email) {
      if (!emailRegex.test(email.trim())) {
        alert(`Email ${email} is not valid`)
        return;
      }
    });

    CompanySettingsPage.post_data(setting_identifier, value, url)
  }

  static handle_email_update(e) {
    let $text = $(e.target)
    let setting_identifier = $text.data('setting_identifier')
    let value = $text.val();
    let url = $text.data('url');
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(value)
    {
      $.each(value.split(','), function(index, email) {
        if (!emailRegex.test(email.trim())) {
          alert(`Email ${email} is not valid`)
          return;
        }
      });
    }
    CompanySettingsPage.post_data(setting_identifier, value, url);
  }

  static post_data(setting_identifier, value, url) {
    let form_data = new FormData()
    form_data.append('setting_identifier', setting_identifier)
    form_data.append('value', value)

    let $settings_loading_container = $('#settings_loading_container')
    $settings_loading_container.addClass('loading')

    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {
        $settings_loading_container.removeClass('loading')
      },
      failure: (res) => {
        $settings_loading_container.removeClass('loading')

        try {
          let body = JSON.parse(res.responseText)
          alert(body.error_message)
        } catch {
          alert("Something went wrong. Please refresh the page and contact the Blitz tech team if problem persists.")
        }

        window.location.reload(); //Reload the page to reset the values. A bit of a hack...
      }
    })
  }
}

$(()=>{
  $(document).on('click', '#setting_switch', CompanySettingsPage.handle_switch_click)
  $(document).on('change', '#setting_select', CompanySettingsPage.handle_select_click)
  $(document).on('change', '#active_producers_select', CompanySettingsPage.handle_select_click)
  $(document).on('change', '#setting_text', CompanySettingsPage.handle_text_update)
  $(document).on('change', '#setting_number', CompanySettingsPage.handle_integer_update)
  $(document).on('change', '#setting_email', CompanySettingsPage.handle_email_update)
})

$(function() {
  const $switch = $('[name="enable_subproducer_registrationis_selected"]')

  CompanySettingsPage.handle_agent_registration_link_visibility($switch.is(':checked'));
});

$(function() {
  const $elem = $('#copy_agent_registration_button')



  if ($elem.length > 0) {

    $elem.on('click', event => {
      navigator.clipboard.writeText($elem.val())
      $('#copy_agent_registration_button .copy_icon').addClass('hidden')
      $('#copy_agent_registration_button .checkmark_icon').removeClass('hidden')
      setTimeout(()=>{
        $('#copy_agent_registration_button .copy_icon').removeClass('hidden')
        $('#copy_agent_registration_button .checkmark_icon').addClass('hidden')
      }, 3000)
    })
  }
});